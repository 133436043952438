<template>
  <div class="px-4 pb-12 sm:px-4 lg:px-8">
    <div class="my-8 rounded-lg bg-white p-6">
      <editOfficeForm title="Edit Data Stockist" view-as="stockist" />
    </div>
  </div>
</template>
<script>
import editOfficeForm from '@/components/office/edit-office-form.vue';

export default {
  name: 'EditStockist',
  components: {
    editOfficeForm,
  },
};
</script>
